/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react'
import dynamic from 'next/dynamic'
import type { MegaNavHeaderLink } from './types'

const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))
const Link = dynamic(import('@/components/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))

export const DeskTopTopLink: React.FC<MegaNavHeaderLink & { linksClassName: string }> = ({
  title,
  url,
  linksClassName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mobileBackgroundImage,
  ...rest
}) => (
  <Text
    data-testid={`MegaNav_Top_Navigation_Link_${title.replace(/\s/g, '-')}`}
    className="inline-block min-w-fit text-disabled-darker"
    {...rest}
  >
    <Link
      href={url}
      underline={false}
      target="_self"
      className={linksClassName}
    >
      {title}
    </Link>
  </Text>
)

export interface DesktopTopBarProps {
  maxWidth: string,
  logo: React.ReactNode | string,
  searchBar: React.ReactNode | React.ReactNode[]
  headerLinks: MegaNavHeaderLink[],
}
export const DesktopTopBar: React.FC<DesktopTopBarProps> = ({
  maxWidth = '',
  logo,
  searchBar,
  headerLinks,
}) => {
  const desktopTopBarRef = useRef<HTMLDivElement>(null)
  return (
    <Row divRef={desktopTopBarRef} cols={5} className={`${maxWidth} mx-auto py-6`}>
      <Col span={2} className="flex items-end">
        {!!headerLinks?.length && headerLinks?.map((link, index) => (
          <DeskTopTopLink
            key={`desktop_link_${link.url}`}
            linksClassName={`align-bottom inline-block no-underline ${index === 0 ? '' : 'ml-4 xl:ml-6.5'}`}
            {...link}
          />
        ))}
      </Col>
      <Col span={1} className="text-center">
        {(logo) || (
          <img
            src={`${String(logo)}`}
            alt="company-logo"
            className="min-w-fit mx-4 ml-0 inline-block max-h-desktop-logo min-h-desktop-logo"
          />
        )}
      </Col>
      <Col span={2}>
        <div className="flex flex-row-reverse h-full items-end text-right">
          {searchBar}
        </div>
      </Col>
    </Row>
  )
}

export default DesktopTopBar
